<template>
  <loading v-if="loading" />
  <div v-else>
    <div class="columns is-marginless is-multiline">
      <div v-if="taskIsScheduled" class="column is-12">
        <task-is-scheduled :task="task || {}" />
      </div>

      <div v-if="taskIsQueued" class="column is-12">
        <task-is-queued :task="task || {}" />
      </div>

      <div class="column" :style="{ flexBasis: '360px' }">
        <div class="columns is-multiline">
          <div v-if="userIsAgent" class="column is-12">
            <collapse title="Staff controls">
              <task-controls :task-id="taskId" />
              <site-controls :site-id="task.siteId" />
            </collapse>
          </div>

          <div v-if="task.siteId && site.hostingData" class="column is-12">
            <site-external-logins
              :site="site"
              :tag="'collapse'"
              :title="'External logins'"
            />
          </div>

          <div class="column is-12">
            <task-details :task="task || {}" />
          </div>

          <div v-if="userCanManageParticipants" class="column is-12">
            <collapse title="Manage user access">
              <manage-participants :task-id="task._id" />
            </collapse>
          </div>
        </div>
      </div>

      <div class="column" :style="{ flexGrow: 9999 }">
        <div class="columns is-multiline">
          <div v-if="taskIsOpen" class="column is-12">
            <div class="box">
              <task-post-control-tabs :task-id="taskId" />
            </div>
          </div>
          <task-pinned-posts v-if="userIsAgent" :task-id="taskId" />
          <div class="column is-12">
            <collapse title="Task thread">
              <task-posts-tabs :task-id="taskId" />
            </collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taskProvider from "@shared/tasks/_taskProvider";
export default {
  name: "Task",
  components: {
    "site-external-logins": () => import("@shared/sites/_siteExternalLogins"),
    "task-details": () => import("@shared/tasks/_taskDetails"),
    "task-is-scheduled": () => import("@shared/tasks/_taskIsScheduled"),
    "task-is-queued": () => import("@shared/tasks/_taskIsQueued"),
    "task-controls": () => import("@shared/admin/_taskControls"),
    "site-controls": () => import("@shared/admin/_siteControls"),
    "task-posts-tabs": () => import("@shared/tasks/posts/_postsTabs"),
    "task-post-control-tabs": () =>
      import("@shared/tasks/posts/_postControlTabs"),
    "manage-participants": () =>
      import("@shared/tasks/_manageParticipants.vue"),
    "task-pinned-posts": () => import("@shared/tasks/posts/_pinnedPosts")
  },
  mixins: [taskProvider],
  computed: {
    userIsAgent() {
      return this.$store.getters["user/isAgent"]();
    }
  },
  watch: {
    task(newVal, oldVal) {
      // If access is revoked
      if (this.$_.isEmpty(newVal) && !this.$_.isEmpty(oldVal)) {
        this.$nextTick(() => {
          this.taskNotFound();
        });
      }
    }
  },
  mounted() {
    this.$store.dispatch(
      "user/disableAlertsFor",
      `${this.taskId}-${this.userId}`
    );
  },
  beforeDestroy() {
    this.$store.commit("user/enableAlertsFor", `${this.taskId}-${this.userId}`);
  }
};
</script>
